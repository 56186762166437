@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  font-size: 11px;

  @screen sm {
    font-size: 11px;
  }
  @screen md {
    font-size: 12px;
  }
  @screen lg {
    font-size: 14px;
  }
  @screen xl {
    font-size: 16px;
  }
  @screen 2xl {
    font-size: 18px;
  }
  @screen 4k {
    font-size: 30px;
  }
}


body {
  font-size: 16px;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.main-background {
  background: url('/images/background.jpeg') no-repeat center center fixed;
  background-size: cover;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.loadingButton:not(.flex-row-reverse):before {
  height: 1em;
  width: 1em;
  border-radius: 9999px;
  border-width: 2px;
  animation: spin 2s linear infinite;
  content: "";
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: currentColor;
  border-right-color: currentColor;
}

.loadingButton.flex-row-reverse:after {
  height: 1em;
  width: 1em;
  border-radius: 9999px;
  border-width: 2px;
  animation: spin 2s linear infinite;
  content: "";
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: currentColor;
  border-right-color: currentColor;
}